<template>
    <label v-if="label" :for="id" class="form-label">{{ label }}</label>
    <input 
        type="text" 
        class="form-control" 
        :id="id" 
        :class="[formSubmitted ? (errors ? 'is-invalid' : 'is-valid') : '', this.class]"
        :value="modelValue" 
        @input="$emit('update:modelValue', $event.target.value)" 
        :disabled="disabled" 
    >
    <div class="invalid-feedback" :class="[errors ? 'd-block' : '']">
        {{ errors ? errors[0] : '' }}
    </div>
</template>

<script>
export default {
  props: {
    id: String,
    class: String,
    label: String,
    modelValue: String,
    disabled: {
      type: Boolean,
      default: false
    },
    errors: Object,
    formSubmitted: {
        type: Boolean,
        default: false
    }
  },
};
</script>
