<template>
    <div class="row">
        <div class="col-md-4">
            <div class="exercise-box border border-light" v-if="exercise.thumbnail"><img :src="exercise.thumbnail" :alt="trans('image_missing')"></div>
            <div class="exercise-box border border-light" v-else>{{ trans('image_missing') }}</div>
        </div>
        <div class="col-md-8">
            <h1 class="hse-text mb-4">{{ exercise.name }}</h1>
            <div class="mb-2 edit-deatils item-space">
                <a href="#" data-bs-toggle="modal" data-bs-target="#editExercise"><i class="icon_pencil-edit me-1"></i>{{ trans('edit_details') }}</a>
               <file-upload buttonClasses="btn btn-primary custom-btn-primary ms-2" :buttonText="buttonText" :maxFileSize="this.maxFileSize" :allowedFileTypes="this.allowedFileTypes" :maxNumberOfFiles="this.maxNumberOfFiles" :uploadUrl="exercise.file_id? '/exercise/update/content' : '/exercise/content'"
               :exercise="exercise" :folder-name ="folderName"/>
               <button type="button" class="btn btn-danger custom-btn-danger ms-2" v-if="exercise.roleId == exercise.superAdminRoleId" @click.prevent="deleteExercise(exercise.id, exercise.name)">{{ trans('delete_exercise') }}</button>
            </div>

            <div class="text-content float-start me-5">{{ exercise.description }}<br>
                {{ trans('date_created') }}: {{ exercise.created }}<br>
                {{ trans('active_links') }}: {{ exercise.activeCount }}<br>
                {{ trans('expired_links') }}: {{ exercise.expiredCount }}<br>
                {{ trans('private_link') }} : <i class="icon_documents_alt copy-private-link-icon copy-button cursor-pointer" v-on:click="copyExercisePrivateUrl()" :title="trans('copy_link')" id="copyMsg"></i>
            </div>
            <div class="text-content float-start me-2">
                {{ trans('size_file_folder') }} : {{ exercise.total_size }} <br>
                {{ trans('amount_of_files') }} : {{ exercise.total_file }}<br>
                {{ trans('uploaded_by') }} : {{ exercise.uploaded_by }}<br>
                {{ trans('date_created') }} : {{ exercise.created_at }}
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-4">
            <span class="links-title">{{ trans('links') }}</span>
            <button type="button" class="btn btn-primary custom-btn-primary float-end" data-bs-toggle="modal" data-bs-target="#newExerciseSession">
                {{ trans('create_link') }}
            </button>
        </div>
        <div class="col-md-8">
            <form class="row gy-2 gx-3 align-items-center float-end" @submit.prevent="searchExerciseLink">
                <div class="col-auto">
                    <TextInput id="autoSizingInput" class="search-links" v-model="searchTerm" :placeholder="placeholder" />
                </div>
                <div class="col-auto">
                    <button type="submit" class="btn btn-primary custom-btn-primary">{{ trans('search') }}</button>
                </div>
            </form>
        </div>
    </div>
    <div class="row mt-3">
        <div class="table-responsive">
            <table class="table table-borderless exercise-table item-space">
                <thead>
                    <tr>
                        <th scope="col">{{ trans('title') }}</th>
                        <th scope="col">{{ trans('opened') }}</th>
                        <th scope="col">{{ trans('link') }}</th>
                        <th scope="col">{{ trans('created') }}</th>
                        <th scope="col">{{ trans('expires_in') }}</th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="sessions.length" v-for="(session, index) in sessions" :key="index">
                        <td>
                            {{ session.title }}<br>
                            <span class="text-email item-space">{{ session.recipients }}</span>
                        </td>
                        <td>{{ session.opened_count }}</td>
                        <td>
                            <div class="d-flex">
                                <TextInput :id="'publicUrl' + index" v-model="sessions[index].public_url" class="form-control-sm public-url"/>
                                <i class="icon_documents_alt copy-icon copy-button" v-on:click="copyExercisePublicUrl(index)" :title="trans('copy_link')" :id="'tooltip'+ index"></i>
                            </div>
                        </td>
                        <td>{{ session.created }}</td>
                        <td><span :class="sessions[index].link_expired? 'text-danger': ''">{{ session.expires_in_show }}</span></td>
                        <td>
                            <a class="btn btn-xs btn-sm"  alt="edit" @click.prevent="editExerciseLink(session)" data-bs-toggle="modal" data-bs-target="#newExerciseSessionEdit">
                                <i class="icon_pencil-edit table-icon item-space"></i>
                            </a>&nbsp;
                            <a href="#" class="btn btn-xs btn-sm" @click.prevent="deleteExerciseSession(session.id, session.title)">
                                <i class="icon_archive_alt table-icon item-space delete-icon-color"></i>
                            </a>
                        </td>
                    </tr>
                    <tr v-else>
                        <td colspan="6" class="text-center">{{ trans('no_result_found') }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- Modal -->
        <div class="modal fade" id="newExerciseSessionEdit" tabindex="-1" aria-labelledby="newExerciseSessionLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content modal-custom-content">
                <div class="modal-header modal-custom-header">
                <h5 class="modal-title" id="newExerciseSessionLabel">{{ trans('update_new_link') }}</h5>
                <button type="button" class="btn-custom-close icon_close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form @submit.prevent="updateExerciseSession">
                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="title" class="form-label">{{ trans('title') }}<span class="required-star-mark">*</span></label>
                            <TextInput id="title" class="form-control-sm" v-model="exerciseLink.title" :errors="validationErrors['title']" :form-submitted="submittedForm" />
                        </div>
                        <div class="mb-3">
                            <label for="expires_in" class="form-label">{{ trans('expires_in') }}<span class="required-star-mark">*</span></label>
                            <DateInput id="expires_in" class="form-control-sm" v-model="exerciseLink.expires_in" :errors="validationErrors['expires_in']" :form-submitted="submittedForm" />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary custom-btn-primary" data-bs-dismiss="modal">{{ trans('cancel') }}</button>
                        <button type="submit" class="btn btn-primary custom-btn-primary">{{ trans('save') }}</button>
                    </div>
                </form>
            </div>
            </div>
        </div>
        <!-- Modal -->
        <div class="modal fade" id="editExercise" tabindex="-1" aria-labelledby="editExerciseLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content modal-custom-content">
                    <div class="modal-header modal-custom-header">
                        <h5 class="modal-title" id="editExerciseLabel">{{ trans('update_exercise') }}</h5>
                        <button type="button" class="btn-custom-close icon_close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form @submit.prevent="editExercise">
                        <div class="modal-body">
                            <div class="mb-3">
                                <label for="name" class="form-label">{{ trans('name') }}<span class="required-star-mark">*</span></label>
                                <TextInput id="name" class="form-control-sm" v-model="exercise.name" :errors="validationErrors['name']" :form-submitted="submittedForm" />
                            </div>
                            <div class="mb-3">
                                <label for="description" class="form-label">{{ trans('description') }}<span class="required-star-mark">*</span></label>
                                <TextArea id="description" class="form-control-sm" v-model="exercise.description" :errors="validationErrors['description']" :form-submitted="submittedForm" />
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary custom-btn-primary" data-bs-dismiss="modal">{{ trans('cancel') }}</button>
                            <button type="submit" class="btn btn-primary custom-btn-primary">{{ trans('save') }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
    <pagination v-if="totalPages > 0" @pageChanged="onChangePage" :total-pages="totalPages" :per-page="perPage" :current-page="currentPage" />
</template>

<script>
    import TextInput from '../common/inputs/TextInput.vue';
    import DateInput from '../common/inputs/DateInput.vue';
    import DropDownList from '../common/inputs/DropDownList.vue';
    import TextArea from '../common/inputs/TextArea.vue';
    import FileUpload from '../FileUpload.vue';
    import Pagination from '../Pagination.vue';
    export default {
        components: {
            TextInput,
            DateInput,
            DropDownList,
            TextArea,
            FileUpload,
            Pagination
        },
        props: {
            exercise: {
                type: Object
            },
            sessionList: {
                type: Object
            },
            totalRows: {
                type: Number
            },
            perPage : {
                type: Number
            },
            exerciseId: {
                type: Number
            }
        },
        data() {
            return {
                statusData: [
                    { value: 0, text: 'Public' }
                ],
                submittedForm: false,
                validationErrors: [],
                placeholder: this.trans('enter_search_terms'),
                sessions: this.sessionList,
                totalNumRows: this.totalRows,
                currentPage: 1,
                searchTerm: '',
                exercise_id : this.exerciseId? this.exerciseId : null,
                exerciseLink: {
                    id: '',
                    exercise_id: '',
                    exercise_unique_id: '',
                    title: '',
                    expires_in: '',
                    status: 0
                },
                buttonText: this.trans('upload'),
                maxFileSize: null,
                allowedFileTypes: null,
                maxNumberOfFiles: 1,
                folderName: "exercise"
            }
        },
        computed: {
            totalPages() {
                return Math.ceil(this.totalNumRows / this.perPage);
            }
        },
        mounted() {
            this.editExerciseLink();
        },
        methods: {
            trans(str) {
                return trans(str);
            },
            onChangePage(page) {
                this.currentPage = page;
                let request = get('/exercise/'+ this.exercise.unique_id + '?offset='+ page + '&searchTerm=' + this.searchTerm);
                let self = this;

                request.then(function (response) {

                    self.sessions = response.sessions;
                    self.totalNumRows = response.totalRows;
                });
            },
            searchExerciseLink() {
                let searchEmptyAction = false;
                if(!this.searchTerm) searchEmptyAction = true
                let request = get('/exercise/'+ this.exercise.unique_id + '?searchTerm='+ this.searchTerm + '&searchEmptyAction=' + searchEmptyAction);
                let self = this;

                request.then(function (response) {
                    self.sessions = response.sessions;
                    self.totalNumRows = response.totalRows;
                });
            },
            editExerciseLink(session) {
                if(session) {
                    this.exerciseLink = {
                        id: session.id,
                        exercise_id: session.exercise_id,
                        title: session.title,
                        expires_in: session.expires_in,
                        status: session.status ? 1 : 0
                    }
                    //this.$modal.show("newExerciseSessionEdit");
                }
            },
            updateExerciseSession() {
                let url = '/update-exercisesession';
                let request = postForm(url, null, {id: this.exerciseLink.id, title: this.exerciseLink.title,
                        expires_in: this.exerciseLink.expires_in, status: this.exerciseLink.status, exercise_unique_id: this.exercise.unique_id});
                let self = this;

                request.then(function (response) {
                    self.submittedForm = false;
                    self.validationErrors = [];
                    window.location.href = '/exercise/' + response.exercise_unique_id;
                });

                request.catch(function(error) {

                    self.submittedForm = true;

                    if (error.response.status == 422) {
                       self.validationErrors = error.response.data.errors;
                    }
                });
            },
            deleteExerciseSession(id, title) {
                this.$prompt(
                    this.trans('delete_exercise_link_confirm'),
                    '',
                    '',
                    '',
                    {
                        title: '<h4 class="mb-0">'+ this.trans('delete_exercise_link') +': '+ title +'</h4>',
                        buttonsStyling: false,
                        customClass: {
                            confirmButton: 'btn btn-danger m-1',
                            cancelButton: 'btn btn-primary m-1',
                            input: 'form-control',
                        }
                    }
                ).then((text) => {
                    if (text.trim() == 'delete') {
                        let request = post('/exercise-session/delete', {id: id});
                        let self = this;

                        request.then(function (response) {

                            for (let index in self.sessions) {
                                if (self.sessions[index].id == response.data.id)
                                {
                                    self.sessions.splice(index, 1);
                                }
                            }

                            toastMessage('success', response.data.message);
                        });
                    }
                }).catch((error) => {

                });
            },
            editExercise()
            {
                let url = '/update-exercise';
                let request = postForm(url, null, {id: this.exercise.id, name: this.exercise.name, description: this.exercise.description });
                let self = this;

                request.then(function (response) {
                    self.submittedForm = false;
                    self.validationErrors = [];
                    window.location.href = '/exercise/' + response.exercise_id;
                });

                request.catch(function(error) {

                    self.submittedForm = true;

                    if (error.response.status == 422) {
                       self.validationErrors = error.response.data.errors;
                    }
                });
            },
            deleteExercise(id, name){
                this.$prompt(
                    this.trans('delete_exercise_confirm'),
                    '',
                    '',
                    '',
                    {
                        title: '<h4 class="mb-0">'+ this.trans('delete_exercise') +': '+ name +'</h4>',
                        buttonsStyling: false,
                        customClass: {
                            confirmButton: 'btn btn-danger m-1',
                            cancelButton: 'btn btn-primary m-1',
                            input: 'form-control',
                        }
                    }
                ).then((text) => {
                    if (text.trim() == 'delete') {
                        let request = post('/exercise/delete', {id: id});
                        let self = this;

                        request.then(function (response) {

                            toastMessage('success', response.data.message);

                            window.location.href = '/library';

                        });
                    }
                }).catch((error) => {

                });
            },
            copyExercisePublicUrl(id)
            {
                setTimeout(function() {
                    var copiedTip = document.getElementById('tooltip' + id);
                    copiedTip.innerHTML = "";
                }, 800);

                var copyText = document.getElementById('publicUrl'+ id);
                copyText.select();
                document.execCommand('copy');

                var tooltip = document.getElementById('tooltip' + id);
                tooltip.innerHTML = "<div class='tip' id='copied_tip' style='font-family:acumin-pro-condensed,sans-serif'>" + trans('copied') + "</div>";
            },
            copyExercisePrivateUrl()
            {
                setTimeout(function() {
                    var copiedTip = document.getElementById('copyMsg');
                    copiedTip.innerHTML = "";
                }, 800);

                var privateUrl = document.body.appendChild(document.createElement("input"));
                privateUrl.value = window.location.href.replace('exercise', 'exercise-content-show');
                privateUrl.focus();
                privateUrl.select();
                document.execCommand('copy');
                privateUrl.parentNode.removeChild(privateUrl);

                var tooltip = document.getElementById('copyMsg');
                tooltip.innerHTML = "<div class='tip' id='copied_tip' style='font-family:acumin-pro-condensed,sans-serif'>" + trans('copied') + "</div>";
            }
        }
    }
</script>
