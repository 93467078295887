<template>
    <label :for="id" class="form-label">{{ label }}</label>
    <textarea 
        class="form-control" 
        rows="3" 
        :id="id" 
        :class="[formSubmitted ? (errors ? 'is-invalid' : 'is-valid') : '', this.class]" 
        :value="modelValue" 
        @input="$emit('update:modelValue', $event.target.value)"
    ></textarea>
    <div class="invalid-feedback" :class="[errors ? 'd-block' : '']">
        {{ errors ? errors[0] : '' }}
    </div>
</template>

<script>
export default {
  props: {
    id: String,
    class: String,
    label: String,
    modelValue: String,
    errors: Object,
    formSubmitted: {
        type: Boolean,
        default: false
    }
  },
};
</script>
