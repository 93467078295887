require('./bootstrap');

import { createApp } from 'vue';
import UserList from './components/users/UserList.vue';
import User from './components/users/User.vue';
import CreateExercise from './components/exercises/CreateExercise.vue';
import CreateExerciseSession from './components/exercises/CreateExerciseSession.vue';
import ExerciseLibraryList from './components/exercises/ExerciseLibraryList.vue';
import ExerciseSessionList from './components/exercises/ExerciseSessionList.vue';
import VueSimpleAlert from "vue3-simple-alert-next";
import Profile from './components/profile/Profile.vue';
import ChangePassword from './components/profile/ChangePassword.vue';

const app = createApp({
    components: {
        UserList,
        User,
        CreateExercise,
        CreateExerciseSession,
        ExerciseSessionList,
        ExerciseLibraryList,
		Profile,
        ChangePassword,
    }
});

app.use(VueSimpleAlert);
app.mount("#app");