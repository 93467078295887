<template>
    <button type="button" :id="`selectFiles${fileUploadNumber}`" :class="[buttonClasses]">{{ buttonText }}</button>
</template>

<script>
    import {BasePlugin} from "@uppy/core";

    const Uppy = require("@uppy/core");
    const Dashboard = require("@uppy/dashboard");
    import AwsS3 from '@uppy/aws-s3';
    import ms from "ms";

    let folderUpload = true;
    let numberOfFiles;
    let totalFileSize;

    require("@uppy/core/dist/style.css");
    require("@uppy/dashboard/dist/style.css");

    class PresignedGeneratorPlugin extends BasePlugin {
        constructor (uppy, opts) {
            super(uppy, opts)
            this.id = opts.id || 'PresignedGeneratorPlugin'
            this.type = 'example'
            this.uniqueId = opts.uniqueId;
            PresignedGeneratorPlugin.generatePresignedUrlChunks = PresignedGeneratorPlugin.generatePresignedUrlChunks.bind(this) // ← this!
        }

        delayMillisecond(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }

        static async generatePresignedUrlChunks(fileIDs) {

            var uppyStatusBarActions = document.getElementsByClassName("uppy-StatusBar-actions");
            var uploadButton = document.getElementsByClassName("uppy-StatusBar-actionBtn--upload");
            var presignedLoaderDiv = document.createElement('div');
            presignedLoaderDiv.classList.add('presigned-loader');
            presignedLoaderDiv.setAttribute("id", "presignedLoader");
            uppyStatusBarActions[0].appendChild(presignedLoaderDiv);
            uploadButton[0].disabled =true;

            let files = [];

            fileIDs.map((fileID) => {
                const file = this.uppy.getFile(fileID)
                file["relativePath"] = file.data.webkitRelativePath;
                files.push(file);
            });

            let fileChunks = [];

            const chunkSize = 250;

            for (let i = 0; i < files.length; i += chunkSize) {
                const chunk = files.slice(i, i + chunkSize);
                fileChunks.push(chunk);
            }

            let requests = [];

            for (const chunk of fileChunks) {

                let url = '/generate-presigned-url-chunk';
                let request = postForm(url, null, {fileChunks: chunk, uniqueId: this.uniqueId });
                let self = this;

                request.then(function (response) {
                    const data = response.data;
                    Object.keys(data).forEach(function (key) {
                        self.uppy.setFileState(response.data[key]["id"], {presignedUrl: response.data[key]["url"]})
                    });
                });

                requests.push(request);

                await this.delayMillisecond(500);
            }

            return Promise.all(requests);
        }

        install () {
            this.uppy.addPreProcessor(PresignedGeneratorPlugin.generatePresignedUrlChunks)
        }

        uninstall () {
            this.uppy.removePreProcessor(PresignedGeneratorPlugin.generatePresignedUrlChunks)
        }
    }

    export default {
        props: {
            fileUploadNumber: Number,
            exercise: {
                type: Object
            },
            folderName: String,
            maxFileSize: {
                type: Number,
                default: null
            },
            allowedFileTypes: {
                type: Array,
                default: null
            },
            buttonClasses: {
                type: String,
                default: 'btn btn-primary d-block'
            },
            dataChunks: {
                type: Array,
                default: []
            },
            // folderUpload: {
            //     type: Boolean,
            //     default: true
            // },
            buttonText: String,
            uploadUrl: String
        },
        methods: {

        },
        mounted() {
            let self = this;
            const uppy = new Uppy({
                restrictions: {
                    maxFileSize: this.maxFileSize,
                    allowedFileTypes: this.allowedFileTypes,
                    maxNumberOfFiles: this.maxNumberOfFiles,
                },
                onBeforeUpload: (files) => {
                    folderUpload = true;
                    numberOfFiles = Object.keys(files).length;

                    if(Object.keys(files).length == 1) {
                        folderUpload = false;
                    }
                    totalFileSize = 0;

                    for (var key in files) {
                        totalFileSize = totalFileSize + files[key].size;
                    }
                    return true;
                }
            }).use(Dashboard, {
                proudlyDisplayPoweredByUppy: false,
                trigger: "#selectFiles" + this.fileUploadNumber,
                closeAfterFinish: true,
                fileManagerSelectionType: 'both',
                showSelectedFiles: false,
                disableThumbnailGenerator: true,
                closeModalOnClickOutside: false
            }).use(PresignedGeneratorPlugin, {
                "uniqueId" : self.exercise.unique_id
            }).use(AwsS3, {
                limit: 1,
                timeout: ms('5 minute'),

                getUploadParameters(file) {
                    //console.log(file);
                    document.getElementById('presignedLoader').style.display = 'none';
                    return {
                        method: 'PUT',
                        url: file["presignedUrl"],
                        fields: [],
                        headers: {
                            'Content-Type' : file.type,
                        }
                    }
                }
            });
            uppy.on('upload-success', (file, data) => {

                if(!folderUpload) {
                    let formData = new FormData()

                    formData.append('s3FileName', file.meta.name);
                    formData.append('s3FileType', file.meta.type);
                    formData.append('s3FileUrl', data.uploadURL);
                    formData.append('id', self.exercise.id);
                    formData.append('unique_id', self.exercise.unique_id);
                    formData.append('file_id', self.exercise.file_id);
                    formData.append('folderUpload', false);
                    formData.append('numberOfFiles', numberOfFiles);
                    formData.append('totalFileSize', totalFileSize);

                    let request = post(this.uploadUrl, formData);

                    request.then(function (response) {
                        toastMessage('success', response.data.message);
                        window.location.href = '/exercise/' + self.exercise.unique_id;
                    });
                }
            });

            uppy.on("complete", result => {

                if(folderUpload && result.successful && result.successful.length) {
                    toastMessage('info_upload', 'Please wait...');

                    let folderName = result.successful[0].data.webkitRelativePath.split('/')[0];
                    let formData = new FormData()

                    formData.append('s3FileType', 'folder');
                    formData.append('id', self.exercise.id);
                    formData.append('unique_id', self.exercise.unique_id);
                    formData.append('file_id', self.exercise.file_id);
                    formData.append('folderUpload', true);
                    formData.append('folderName', folderName);
                    formData.append('numberOfFiles', numberOfFiles);
                    formData.append('totalFileSize', totalFileSize);

                    let request = post(this.uploadUrl, formData);

                    request.then(function (response) {
                        toastMessage('success', response.data.message);
                        window.location.href = '/exercise/' + self.exercise.unique_id;
                    });
                }
                uppy.reset();
            });
        }
    }
</script>
