<template>
    <div class="row">
        <label v-if="label" :for="id" class="col-md-3 form-label col-form-password text-end">{{ label }}</label>
        <div class="col-md-9">
            <input 
                type="password" 
                class="form-control" 
                :id="id" 
                :class="[formSubmitted ? (errors ? 'is-invalid' : 'is-valid') : '', this.class]"
                :value="modelValue" 
                @input="$emit('update:modelValue', $event.target.value)" 
            >
            <div class="invalid-feedback" :class="[errors ? 'd-block' : '']">
                {{ errors ? errors[0] : '' }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    id: String,
    class: String,
    label: String,
    modelValue: String,
    errors: Object,
    formSubmitted: {
        type: Boolean,
        default: false
    }
  },
};
</script>
