<template>
    <form @submit.prevent="updatePassword">
        <div class="mb-3">
            <password-custom-input :label="trans('current_password')" id="currentPassword" v-model="currentPassword" :errors="validationErrors['current_password']" :form-submitted="submittedForm" />  
        </div>
        <div class="mb-3">
            <password-custom-input :label="trans('new_password')" id="newPassword" v-model="password" :errors="validationErrors['password']" :form-submitted="submittedForm" />  
        </div>
        <div class="mb-3">
            <password-custom-input :label="trans('password_confirmation')" id="passwordConfirmation" v-model="passwordConfirmation" />  
        </div>
        <div class="row mb-0">
            <div class="col-md-9 offset-md-3">
                <a href="/library" class="btn btn-primary custom-btn-primary me-3">{{ trans('cancel') }}</a>
                <button type="submit" class="btn btn-primary custom-btn-primary">{{ trans('save') }}</button>
            </div>
        </div>
    </form>
</template>

<script>
    import PasswordCustomInput from '../common/inputs/PasswordCustomInput.vue';

    export default {
        components: {
            PasswordCustomInput
        },
        data() {
            return {
                currentPassword: '',
                password: '',
                passwordConfirmation: '',
                submittedForm: false,
                validationErrors: [],
            }
        },
        methods: {
            trans(str) {
                return trans(str);
            },
            updatePassword() {
                let request = postForm('/profile/update-password', null, {current_password: this.currentPassword, password: this.password, password_confirmation: this.passwordConfirmation});
                let self = this;

                request.then(function (response) {
                    self.submittedForm = false;
                    self.validationErrors = [];
                    window.location.href = '/library';
                });

                request.catch(function(error) {
                    self.submittedForm = true;
                    
                    if (error.response.status == 422) {
                       self.validationErrors = error.response.data.errors; 
                    }
                });
            }
        }
    }
</script>
