<template>
    <div>
        <ul class="pagination justify-content-center">
            <li class="page-item" :class="[firstPage ? 'disabled' : '' ]">
                <a class="page-link" href="#" @click.prevent="onClickFirstPage">{{ trans('first') }}</a>
            </li>
            <li class="page-item" :class="[firstPage ? 'disabled' : '' ]">
                <a class="page-link" href="#" @click.prevent="onClickPrevPage">{{ trans('previous') }}</a>
            </li>
            <li class="page-item" v-for="page in pages" :class="[page.isDisabled ? 'disabled' : '' ]">
                <a class="page-link" href="#" @click.prevent="onClickPage(page.name)">{{ page.name }}</a>
            </li>
            <li class="page-item" :class="[lastPage ? 'disabled' : '' ]">
                <a class="page-link" href="#" @click.prevent="onClickNextPage">{{ trans('next') }}</a>
            </li>
            <li class="page-item" :class="[lastPage ? 'disabled' : '' ]">
                <a class="page-link" href="#" @click.prevent="onClickLastPage">{{ trans('last') }}</a>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        props: {
            maxVisibleLinks: {
                type: Number,
                required: false,
                default: 3
            },
            totalPages: {
                type: Number,
                required: true
            },
            perPage: {
                type: Number,
                required: true
            },
            currentPage: {
                type: Number,
                required: true
            }
        },
        computed: {
            startPage() {
                if (this.currentPage === 1) {
                    return 1;
                }

                if (this.currentPage === this.totalPages) {
                    if (this.totalPages > this.maxVisibleLinks) {
                        return this.totalPages - this.maxVisibleLinks;
                    }
                    
                    return 1;
                }

                return this.currentPage - 1; 
            },
            pages() {
                const range = [];
                
                for (
                    let i = this.startPage;
                    i <= Math.min(this.startPage + this.maxVisibleLinks, this.totalPages);
                    i++
                ) {
                    range.push({
                        name: i,
                        isDisabled: i === this.currentPage
                    });
                }

                return range;
            },
            firstPage() {
                return this.currentPage === 1;
            },
            lastPage() {
                return this.currentPage === this.totalPages;
            },
        },
        methods: {
            trans(str) {
                return trans(str);
            },
            onClickFirstPage() {
                this.$emit('pageChanged', 1);
            },
            onClickPrevPage() {
                this.$emit('pageChanged', this.currentPage - 1);
            },
            onClickNextPage() {
                this.$emit('pageChanged', this.currentPage + 1);
            },
            onClickPage(page) {
                this.$emit('pageChanged', page);
            },
            onClickLastPage() {
                this.$emit('pageChanged', this.totalPages);
            }
        }
    }
</script>
