<template>
    <form @submit.prevent="saveProfile">
        <div class="mb-3">
            <text-custom-input :label="trans('name')" id="name" v-model="userData.name" :errors="validationErrors['name']" :form-submitted="submittedForm" />  
        </div>
        <div class="mb-3">
             <email-custom-input :label="trans('email_address')" id="email" v-model="userData.email" :disabled="isEmailDisabled" />
        </div>
        <div class="row mb-0">
            <div class="col-md-9 offset-md-3">
                <a href="/library" class="btn btn-primary custom-btn-primary me-3">{{ trans('cancel') }}</a>
                <button type="submit" class="btn btn-primary custom-btn-primary">{{ trans('save') }}</button>
            </div>
        </div>
    </form>
</template>

<script>
    import TextCustomInput from '../common/inputs/TextCustomInput.vue';
    import EmailCustomInput from '../common/inputs/EmailCustomInput.vue';

    export default {
        components: {
            TextCustomInput,
            EmailCustomInput,
        },
        props: {
            userData: Object
        },
        data() {
            return {
                submittedForm: false,
                validationErrors: [],
                isEmailDisabled: true,
            }
        },
        methods: {
            trans(str) {
                return trans(str);
            },
            saveProfile() {
                let request = postForm('/profile/save', null, {name: this.userData.name});
                let self = this;

                request.then(function (response) {
                    self.submittedForm = false;
                    self.validationErrors = [];
                    window.location.href = '/library';
                });

                request.catch(function(error) {
                    self.submittedForm = true;
                    
                    if (error.response.status == 422) {
                       self.validationErrors = error.response.data.errors; 
                    }
                });
            }
        }
    }
</script>
