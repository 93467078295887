<template>
    <table class="table item-space">
        <thead>
            <tr>
                <th>{{ trans('name') }}</th>
                <th>{{ trans('email_address') }}</th>
                <th>{{ trans('ad_user') }}</th>
                <th></th>
                <th></th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(user, index) in users" :key="index">
                <td>{{ user.name }}</td>
                <td>{{ user.email }}</td>
                 <td>{{ user.social_id ? trans('yes') : trans('no') }}</td>
                 <td>
                    <a v-if="!user.password && !user.social_id" href="#" class="btn btn-xs btn-sm" @click.prevent="resendLink(user.email)">
                        <i class="far fa-reply table-icon item-space text-secondary"></i>
                    </a>                 
                </td>              
                <td>
                    <a class="btn btn-xs btn-sm"  alt="edit" @click.prevent="editUser(user)" data-bs-toggle="modal" data-bs-target="#editUser">
                        <i class="far fa-edit table-icon item-space"></i>
                    </a>                 
                </td>
                <td>
                    <a v-if="!user.social_id" href="#" class="btn btn-xs btn-sm" @click.prevent="deleteUser(user.id, user.name)">
                        <i class="fas fa-calendar-minus table-icon item-space color-red"></i>
                    </a>                    
                </td>
            </tr>
        </tbody>
    </table>
    <pagination v-if="totalPages > 0" @pageChanged="onChangePage" :total-pages="totalPages" :per-page="perPage" :current-page="currentPage" />

    <!-- Modal -->
    <div class="modal fade" id="editUser" tabindex="-1" aria-labelledby="editUserLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
            <h5 class="modal-title" id="editUserLabel">{{ trans('edit_user') }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form @submit.prevent="updateUser">
                    <div class="mb-3 row">
                        <label for="name" class="col-md-4 col-form-label text-md-right">{{ trans('name') }}</label>

                        <div class="col-md-6">               
                            <TextInput id="name" v-model="userData.name" :errors="validationErrors['name']" :form-submitted="submittedForm" :disabled="userData.social_id" />              
                        </div>
                    </div>

                    <div class="mb-3 row">
                        <label for="email" class="col-md-4 col-form-label text-md-right">{{ trans('email_address') }}</label>

                        <div class="col-md-6">
                            <EmailInput id="email" v-model="userData.email" :errors="validationErrors['email']" :form-submitted="submittedForm" :disabled="userData.social_id" />
                        </div>
                    </div>

                    <div class="mb-3 row">
                        <label for="roles" class="col-md-4 col-form-label text-md-right">{{ trans('roles') }}</label>

                        <div class="col-md-6">
                            <DropDownList id="roles" v-model="userData.roles" :options="roleOptions" :errors="validationErrors['roles']" :form-submitted="submittedForm" />
                        </div>
                    </div>
                    <div class="row mb-0">
                        <div class="col-md-6 offset-md-4">
                            <button type="submit" class="btn btn-primary">
                                {{ trans('save') }}
                            </button>
                        </div>
                    </div>
                </form>                
            </div>
            <div class="modal-footer"></div>
        </div>
        </div>
    </div>
</template>

<script>
    import TextInput from '../common/inputs/TextInput.vue';
    import EmailInput from '../common/inputs/EmailInput.vue';
    import DropDownList from '../common/inputs/DropDownList.vue';
    import Pagination from '../Pagination.vue';

    export default {
        components: {
            TextInput,
            EmailInput,
            DropDownList,
            Pagination
        },
        props: {
            userList: {
                type: Object
            },
            totalRows: {
                type: Number
            },
            user: {
                type: Object
            },
        },
        data() {
            return {
                submittedForm: false,
                validationErrors: [],
                users: this.userList,
                totalNumRows: this.totalRows,               
                perPage: 10,
                currentPage: 1,
                userData: {
                    id: '', 
                    name: '',
                    email: '',
                    roles: '1',
                    social_id: null,
                },
                roleOptions: [
                    { value: 1, text: trans('default_user') },
                    { value: 2, text: trans('admin') }                 
                ],
            }
        },
        computed: {
            totalPages() {
                return Math.ceil(this.totalNumRows / this.perPage);
            }
        },
        mounted() {
            this.editUser();
        },
        methods: {
            trans(str) {
                return trans(str);
            },
            onChangePage(page) {
                this.currentPage = page;
                let offset = this.currentPage - 1;

                let request = get('/users?offset='+ offset);
                let self = this;

                request.then(function (response) {
                    self.users = response.users;
                    self.totalNumRows = response.totalRows;
                });
            },
            editUser(user) {                
                if(user) {
                    //console.log(user);
                    this.userData = {
                        id: user.id,
                        name: user.name,
                        email: user.email,
                        roles: user.roles,
                        social_id: user.social_id
                    }
                    //this.$modal.show("editUser");
                }
            },
            updateUser() {
                let url = '/user/update';

                let request = postForm(url, null, {id: this.userData.id, name: this.userData.name, email: this.userData.email, roles: this.userData.roles});
                let self = this;

                request.then(function (response) {
                    self.submittedForm = false;
                    self.validationErrors = [];
                    window.location.href = '/users';
                });

                request.catch(function(error) {
                    self.submittedForm = true;
                    
                    if (error.response.status == 422) {
                       self.validationErrors = error.response.data.errors; 
                    }
                });
            },
            deleteUser(id, name) {
                this.$prompt(
                    this.trans('delete_user_confirm'), 
                    '', 
                    '',
                    '',
                    {
                        title: '<h4 class="mb-0">'+ this.trans('delete_user') +': '+ name +'</h4>',
                        buttonsStyling: false, 
                        customClass: {
                            confirmButton: 'btn btn-primary m-1', 
                            cancelButton: 'btn btn-secondary m-1',
                            input: 'form-control',
                        }
                    }
                ).then((text) => {
                    if (text.trim() == 'delete') {
                        let request = post('/user/delete', {id: id});
                        let self = this;
                        request.then(function (response) {
                            for (let index in self.users) {
                                if (self.users[index].id == response.data.id) {
                                    self.users.splice(index, 1);
                                }
                            }
                            toastMessage('success', response.data.message);
                        });
                    }
                }).catch((error) => {
                    
                });                              
            },
            resendLink(email) {
                this.$confirm(
                    this.trans('resend_link_confirm'), 
                    '', 
                    '', 
                    {
                        title: '<h3 class="mb-0">'+ this.trans('resend_link') +'</h3>',
                        buttonsStyling: false, 
                        customClass: {
                            confirmButton: 'btn btn-primary m-1', 
                            cancelButton: 'btn btn-secondary m-1'
                        }
                    }
                ).then(() => {
                    let request = post('/user/resend-link', {email: email});

                    request.then(function (response) {
                        toastMessage('success', response.data.message);
                    });
                }).catch((error) => {
                    
                });
            },
        }
    }
</script>
