<template>
    <form @submit.prevent="saveUser">
        <div class="mb-3 row">
            <label for="name" class="col-md-4 col-form-label text-md-right">{{ trans('name') }}</label>

            <div class="col-md-6">               
                <TextInput id="name" v-model="name" :errors="validationErrors['name']" :form-submitted="submittedForm" />              
            </div>
        </div>

        <div class="mb-3 row">
            <label for="email" class="col-md-4 col-form-label text-md-right">{{ trans('email_address') }}</label>

            <div class="col-md-6">
                <EmailInput id="email" v-model="email" :errors="validationErrors['email']" :form-submitted="submittedForm" />
            </div>
        </div>

        <div class="row mb-0">
            <div class="col-md-6 offset-md-4">
                <button type="submit" class="btn btn-primary">
                    {{ trans('send') }}
                </button>
            </div>
        </div>
    </form>
</template>

<script>
    import TextInput from '../common/inputs/TextInput.vue';
    import EmailInput from '../common/inputs/EmailInput.vue';
    import PasswordInput from '../common/inputs/PasswordInput.vue';

    export default {
        components: {
            TextInput,
            EmailInput          
        },
        props: {
            user: {
                type: Object
            }
        },
        data() {
            return {
                submittedForm: false,
                validationErrors: [],
                id: this.user.id ? this.user.id : null, 
                name: this.user.name ? this.user.name : '',
                email: this.user.email ? this.user.email : ''                
            }
        },
        methods: {
            trans(str) {
                return trans(str);
            },
            saveUser() {
                let url = '/user/create';
                let request = postForm(url, null, {id: this.id, name: this.name, email: this.email});
                let self = this;

                request.then(function (response) {
                    self.submittedForm = false;
                    self.validationErrors = [];
                    window.location.href = '/users';
                });

                request.catch(function(error) {
                    self.submittedForm = true;
                    
                    if (error.response.status == 422) {
                       self.validationErrors = error.response.data.errors; 
                    }
                });
            }
        }
    }
</script>
