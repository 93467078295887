<template>
    <div class="modal-header modal-custom-header">
        <h5 class="modal-title" id="newExerciseLabel">{{ trans('create_exercise') }}</h5>
        <button type="button" class="btn-custom-close icon_close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <form @submit.prevent="saveExercise">
        <div class="modal-body">
            <div class="mb-3">
                <label for="name" class="form-label">{{ trans('name') }}<span class="required-star-mark">*</span></label>
                <TextInput id="name" class="form-control-sm" v-model="name" :errors="validationErrors['name']" :form-submitted="submittedForm" />
            </div>
            <div class="mb-3">
                <label for="description" class="form-label">{{ trans('description') }}<span class="required-star-mark">*</span></label>
                <TextArea id="description" class="form-control-sm" v-model="description" :errors="validationErrors['description']" :form-submitted="submittedForm" />
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary custom-btn-primary" data-bs-dismiss="modal">{{ trans('cancel') }}</button>
            <button type="submit" class="btn btn-primary custom-btn-primary">{{ trans('save') }}</button>
        </div>
    </form>
</template>

<script>
    import TextInput from '../common/inputs/TextInput.vue';
    import TextArea from '../common/inputs/TextArea.vue';
    export default {
        components: {
            TextInput,
            TextArea
        },
        props: {

        },
        data() {
            return {
                submittedForm: false,
                validationErrors: [],
                name: '',
                description: ''
            }
        },
        methods: {
            trans(str) {
                return trans(str);
            },
            saveExercise() {
                let url = '/create-exercise';
                let request = postForm(url, null, { name: this.name, description: this.description });
                let self = this;

                request.then(function (response) {
                    self.submittedForm = false;
                    self.validationErrors = [];
                    window.location.href = '/library';
                });

                request.catch(function(error) {
                    self.submittedForm = true;

                    if (error.response.status == 422) {
                       self.validationErrors = error.response.data.errors;
                    }
                });
            }
        }
    }
</script>
