<template>
    <div class="modal-header modal-custom-header">
          <h5 class="modal-title" id="newExerciseSessionLabel">{{ trans('create_new_link') }}</h5>
          <button type="button" class="btn-custom-close icon_close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <form @submit.prevent="saveExercise">
        <div class="modal-body"> 
            <div class="mb-3">
                <label for="title" class="form-label">{{ trans('title') }}<span class="required-star-mark">*</span></label>
                <TextInput id="title" class="form-control-sm" v-model="title" :errors="validationErrors['title']" :form-submitted="submittedForm" />              
            </div>    
            <div class="mb-3">
                <label for="expires_in" class="form-label">{{ trans('expires_in') }}<span class="required-star-mark">*</span></label>
                <DateInput id="expires_in" class="form-control-sm" v-model="expires_in" :errors="validationErrors['expires_in']" :form-submitted="submittedForm" />              
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary custom-btn-primary" data-bs-dismiss="modal">{{ trans('cancel') }}</button>
            <button type="submit" class="btn btn-primary custom-btn-primary">{{ trans('save') }}</button>
        </div>
    </form>
</template>

<script>
    import TextInput from '../common/inputs/TextInput.vue';
    import DateInput from '../common/inputs/DateInput.vue';
    import DropDownList from '../common/inputs/DropDownList.vue';
    export default {
        components: {
            TextInput,
            DateInput,
            DropDownList      
        },
        props: {
            exerciseLink: {
                type: Object
            },
            exerciseId: {
                type: Number
            },
            exerciseUniqueId : {
                type: String
            }            
        },
        data() {           
            return {
                submittedForm: false,
                validationErrors: [],
                id: this.exerciseLink.id ? this.exerciseLink.id : null, 
                title: this.exerciseLink.title ? this.exerciseLink.title : '',
                recipients: this.exerciseLink.recipients ? this.exerciseLink.recipients : '',
                created: this.exerciseLink.created ? this.exerciseLink.created : '',
                expires_in: this.exerciseLink.expires_in ? this.exerciseLink.expires_in : '',
                exercise_id : this.exerciseId? this.exerciseId : null,
                exercise_unique_id: this.exerciseUniqueId? this.exerciseUniqueId : null               
            }
        },
        methods: {
            trans(str) {
                return trans(str);
            },
            saveExercise() {
                let url = '/create-exercisesession';

                let request = postForm(url, null, {id: this.id, exercise_id: this.exercise_id, exercise_unique_id: this.exercise_unique_id, title: this.title, recipients: 
                        this.recipients, expires_in: this.expires_in });
                let self = this;

                request.then(function (response) {
                                        
                    self.submittedForm = false;
                    self.validationErrors = [];
                    window.location.href = '/exercise/' + response.exercise_unique_id;
                });

                request.catch(function(error) {
                    self.submittedForm = true;
                    
                    if (error.response.status == 422) {
                       self.validationErrors = error.response.data.errors; 
                    }
                });
            }
        }
    }
</script>