<template>
    <div class="row mb-3">
        <div class="col-md-2">
            <h3 class="content-caption">{{ trans('your_content') }}</h3>
        </div>
        <div class="col-md-2">                                           
            <div class="d-flex w-25">
                <span class="me-2 sort-text"> {{ trans('sort') }}: </span>
                <DropDownList id="sorting" v-model="sorting" :options="sortingOptions"/>                    
            </div>
        </div>
        <div class="col-md-8">
            <form class="row justify-content-end" @submit.prevent="searchExercise">
                <div class="col-auto">
                    <TextInput id="autoSizingInput" class="search-exercises" v-model="searchTerm" :placeholder="placeholder" />                       
                </div>                                
                <div class="col-auto">
                    <button type="submit" class="btn btn-primary custom-btn-primary">{{ trans('search') }}</button>
                </div>
                <div class="col-auto" v-if="userRoleId > 1">
                    <button type="button" class="btn btn-primary custom-btn-primary" data-bs-toggle="modal" data-bs-target="#newExercise">
                        {{ trans('create_exercise') }}
                    </button>
                </div>
            </form>
        </div>
    </div>    
    <div class="row">        
        <div class="col-md-4 mb-3" v-if="exercises.length" v-for="(exercise, index) in exercises" :key="index">
            <div class="exercise-content mb-1 border border-light" v-if="exercises[index].thumbnail">
                <a v-if="thisUserRoleId > 1" :href="`/exercise/${ exercise.unique_id }`"><img :src="exercises[index].thumbnail" :alt="trans('image_missing')"></a>
                <a v-else :href="`/exercise-content-show/${ exercise.unique_id }`" target="_blank"><img :src="exercises[index].thumbnail" :alt="trans('image_missing')"></a>
            </div>
            <div class="exercise-content mb-1 border border-light" v-else>
                <a v-if="thisUserRoleId > 1" :href="`/exercise/${ exercise.unique_id }`">{{ trans('image_missing') }}</a>
                <a v-else :href="`/exercise-content-show/${ exercise.unique_id }`" target="_blank">{{ trans('image_missing') }}</a>
            </div>
            <div class="mb-1">
                <a v-if="thisUserRoleId > 1" :href="`/exercise/${ exercise.unique_id }`">
                    <h2 class="content-title item-space mb-0">{{ exercise.name }}</h2>
                </a>
                <a v-else :href="`/exercise-content-show/${ exercise.unique_id }`" target="_blank">
                    <h2 class="content-title item-space mb-0">{{ exercise.name }}</h2>
                </a>
            </div>           
            <div class="content-footer">
                <p class="mb-0">{{ exercise.description }}</p>
            </div>
        </div>
        <div class="col-md-12 text-center border border-light p-2 mt-5 no-found" v-else>
            {{ trans('no_result_found') }}
        </div>                                
    </div>

    <pagination v-if="totalPages > 0" @pageChanged="onChangePage" :total-pages="totalPages" :per-page="perPage" :current-page="currentPage" />   
</template>
<script>
    import TextInput from '../common/inputs/TextInput.vue';
    import DropDownList from '../common/inputs/DropDownList.vue';
    import Pagination from '../Pagination.vue';
    export default {
        components: {
            TextInput,
            DropDownList,
            Pagination
        },
        props: {
            exerciseList: {
                type: Object
            },
            totalRows: {
                type: Number
            },
            perPage : {
                type: Number
            },
            userRoleId : {
                type: Number
            }
        },
        data() {           
            return {
                exercises: this.exerciseList,
                totalNumRows: this.totalRows,                
                currentPage: 1,
                placeholder: this.trans('enter_search_terms'),
                searchTerm: '',
                sortingOptions:  [                   
                    { value: 'ASC', text: this.trans('a_z') },
                    { value: 'DESC', text: this.trans('z_a') }
                ],
                sorting: 'ASC',
                thisUserRoleId: this.userRoleId
            }
        },
        watch: {
            sorting() {
                let request = get('/library?orderBy='+ this.sorting + "&searchTerm=" + this.searchTerm);
                let self = this;
                request.then(function (response) {
                    self.exercises = response.exercises;
                    self.totalNumRows = response.totalRows;                    
                });
            }
        },
        computed: {
            totalPages() {                         
                return Math.ceil(this.totalNumRows / this.perPage);
            }
        },
        methods: {
            trans(str) {
                return trans(str);
            },
            onChangePage(page) {
                this.currentPage = page;               
                let request = get('/library?offset='+ page + '&searchTerm=' + this.searchTerm + '&orderBy='+ this.sorting);
                let self = this;

                request.then(function (response) {                    
                    self.exercises = response.exercises;
                    self.totalNumRows = response.totalRows;                    
                });
            },
            searchExercise() {               
                let searchEmptyAction = false;
                if(!this.searchTerm) searchEmptyAction = true
                let request = get('/library?searchTerm='+ this.searchTerm + '&searchEmptyAction=' + searchEmptyAction);
                let self = this;
                
                request.then(function (response) {
                    self.exercises = response.exercises;
                    self.totalNumRows = response.totalRows;                    
                });
            }            
        }        
    }
</script>
